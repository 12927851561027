import { useTracker } from '_core/hooks/use-tracker';

type Properties = {
  listOpened?: boolean;
  slugs?: string[];
};

export const trackListOpened = (props: Properties) => {
  const { listOpened } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('listOpened', {
    listOpened,
  });
};

export const trackShareClicked = (props: Properties) => {
  const { slugs } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('shareLinkClicked', {
    slugs,
  });
};
