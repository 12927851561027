import { cx } from '@emotion/css';
import { FC } from 'react';

type MobileMenuHeaderProps = {
  title: string;
  className?: string;
};

export const MobileMenuHeader: FC<MobileMenuHeaderProps> = (props) => {
  const { title, className } = props;

  return (
    <header className={cx(className, 'px-32 pb-16')}>
      <span className="h4 bold text-secondary-900">{title}</span>
    </header>
  );
};
